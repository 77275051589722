import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
const swiper = new Swiper('.awards__slider', {
  slidesPerView: 1.5,
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    1920: {
            slidesPerView: 6,
        },
    1200: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    992: {
            slidesPerView: 4,
            spaceBetween: 40,
        },

    576: {
          slidesPerView: 3,
          spaceBetween: 40,
        }
    },
});

const swiper2 = new Swiper('.team__slider', {
  slidesPerView: 1,
  loop: true,
  centeredSlides: true,
  spaceBetween: 20,
  autoplay: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    992: {
            slidesPerView: 3,
            spaceBetween: 0,
        },
    },
});
