import { validateForms } from '../functions/validate-forms';

const rules = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 6
      },
      {
        rule: 'maxLength',
        value: 50
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Fill in the name!'
      }
    ]
  },
  {
    ruleSelector: '.input-email',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Fill out The Email!'
      },
      {
        rule: 'maxLength',
        value: 50
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Enter the correct email!'
      }
    ]
  },
];


const afterForm = () => {
  console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.trading__form', rules, afterForm);
